<template>
  <div class="app-wrapper">
    <layout-nav></layout-nav>
    <router-view></router-view>
    <layout-footer></layout-footer>
    <layout-aside></layout-aside>
  </div>
</template>

<script>
import LayoutAside from './comps/layoutAside.vue'
import LayoutFooter from './comps/layoutFooter.vue'
import LayoutNav from './comps/layoutNav.vue'
export default {
  components: { LayoutNav, LayoutAside, LayoutFooter },
  name: 'home',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
</style>
